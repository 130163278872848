import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Select,
  MenuItem,
  CircularProgress,
  IconButton,
  Box,
  Typography,
  Grid,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import * as api from "../../APICalls/index";
import FileCopyIcon from "@mui/icons-material/FileCopy";

import CancelIcon from "@mui/icons-material/Cancel";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_WIDGET } from "../../graphql/mutations";
import { GET_PRODUCTS } from "../../graphql/queries";
type propsType = {
  latitude: number | undefined;
  longitude: number | undefined;
  MasjidName: string | undefined;
  masjidId: string;
};
const WidgetComponent: React.FC<propsType> = ({
  latitude,
  longitude,
  MasjidName,
  masjidId,
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [assetType, setSelectedAssetType] = useState<string>("");
  const [baseURL, setBaseURL] = useState<string>("");
  const [tokens, setTokens] = useState<{ [key: string]: string }>({
    event: "",
    notification: "",
    timetable: "",
    boardmember: "",
    service: "",
    program: "",
  });
  const [authenticateWidget, { data, loading, error }] =
    useMutation(CREATE_WIDGET);

  const {
    data: productsData,
    loading: productsLoading,
    error: productsError,
  } = useQuery(GET_PRODUCTS, {
    variables: { masjidId: masjidId, type: "DONATION" },
    skip: assetType !== "donation", // Skip this query if assetType is not 'donation'
    fetchPolicy: "network-only",
  });

  const handleGenerateOldWidget = async () => {
    if (!assetType) {
      toast.error("Please select an asset type");
      return;
    }
    setLoading(true);
    try {
      // Replace this with your actual API endpoint
      const response = await api.createWidget(assetType, masjidId ?? "");
      if (response.status !== 200) {
        throw new Error("Failed to fetch widget data");
      }
      const data = await response.data.data;

      setTokens((prevTokens) => ({
        ...prevTokens,
        [assetType]: data.apiKey,
      }));
      setOpenModal(true);
    } catch (error) {
      console.error("Error fetching widget data:", error);
      toast.error("Failed to generate widget");
    } finally {
      setLoading(false);
    }
  };
  const handleGenerateWidget = async () => {
    if (!assetType) {
      toast.error("Please select an asset type");
      return;
    }

    if (assetType === "donation") {
      // Check if donations are enabled
      if (
        !productsLoading &&
        productsData &&
        productsData.getProducts &&
        productsData.getProducts.filter((product) => product.active).length > 0
      ) {
        // Proceed with widget generation
        const response = await authenticateWidget({
          variables: {
            input: {
              masjidId,
              assetType: assetType,
            },
          },
        });

        if (!loading && !response?.data?.widgetAuth) {
          toast.error("Failed to generate widget");
        } else {
          const token = response?.data?.widgetAuth;
          setTokens((prevTokens) => ({
            ...prevTokens,
            [assetType]: token,
          }));
          setOpenModal(true);
          toast.success("Widget generated successfully ");
        }
      } else if (!productsLoading && productsError) {
        toast.error("Payments are not set up yet!");
      } else if (
        !productsLoading &&
        Array.isArray(productsData?.getProducts) &&
        (productsData.getProducts.length === 0 ||
          productsData.getProducts.filter((product) => product.active)
            .length === 0)
      ) {
        toast.error("No active donations available!");
      } else {
        toast.error("Something went wrong");
      }
    } else {
      // For other asset types, directly call authenticateWidget
      const response = await authenticateWidget({
        variables: {
          input: {
            masjidId,
            assetType: assetType,
          },
        },
      });

      if (!loading && !response?.data?.widgetAuth) {
        toast.error("Failed to generate widget");
      } else {
        const token = response?.data?.widgetAuth;
        setTokens((prevTokens) => ({
          ...prevTokens,
          [assetType]: token,
        }));
        setOpenModal(true);
        toast.success("Widget generated successfully ");
      }
    }
  };
  useEffect(() => {
    const currentDomain = window.location.origin;
    if (currentDomain === "https://main-admin-portal.connectmazjid.com")
      setBaseURL("https://widgets.connectmazjid.com");
    else setBaseURL("https://widgets-connectmasjid.netlify.app");
  }, []);
  const iFrameSetter = () => {
    // Mapping asset types to their respective paths
    const modifiedPath: { [key: string]: string } = {
      event: "events",
      notification: "announcement",
      timetable: "prayer-timing",
      boardmember: "boardmembers",
      service: "services",
      program: "programs",
      donation: "donations",
    };

    // Construct the query string based on asset type
    const queryParams = (() => {
      switch (assetType) {
        case "timetable":
          return `?lat=${latitude}&lon=${longitude}&`;
        case "service":
          return `?lat=${latitude}&lon=${longitude}&`;
        case "program":
        case "boardmember":
          return `?masjidid=${masjidId}&`;
        case "donation":
          return `?masjidid=${masjidId}&`;
        default:
          return "?";
      }
    })();

    // Construct the full iframe URL

    const copyableUrl = `${baseURL}/widget/${modifiedPath[assetType]}${queryParams}token=${tokens[assetType]}`;

    // Construct the iframe HTML code
    const frameCode = `
      <iframe 
        title="${modifiedPath[assetType]} Widget Of ${MasjidName}" 
        src="${copyableUrl}" 
        width="100%" 
        height="640" 
        scrolling="no"
        allow="clipboard-read; clipboard-write"
        >
      </iframe>
    `;

    return frameCode;
  };

  const handleCopyToClipboard = () => {
    if (!assetType) {
      toast.error("Asset type must be selected");
      return;
    }
    if (!tokens[assetType]) {
      toast.error(`${assetType} widget not generated.`);
      return;
    }

    navigator.clipboard
      .writeText(iFrameSetter())
      .then(() => {
        toast.success("Code copied to clipboard");
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
        toast.error("Failed to copy code to clipboard");
      });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedAssetType("");
  };
  const oldWidgetType = ["event", "notification", "timetable"];
  return (
    <div>
      <Button
        style={{
          border: "1px solid green",
          color: "green",
          height: "20px",
          fontSize: "10px",
          marginLeft: "10px",
          marginBottom: "10px",
          cursor: "Pointer",
        }}
        onClick={() => setOpenModal(true)}
      >
        Generate Widget
      </Button>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            width: "400px",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 8,
            p: 4,
          }}
        >
          <Grid container justifyContent="space-between">
            <Typography variant="h6" gutterBottom>
              Select Asset Type
            </Typography>

            <IconButton aria-label="cancel" onClick={handleCloseModal}>
              <CancelIcon />
            </IconButton>
            <Select
              value={assetType}
              id="widget-dropdown"
              onChange={(e) => setSelectedAssetType(e.target.value as string)}
              placeholder="Select Asset Type"
              style={{ marginBottom: "20px" }}
              fullWidth
            >
              <MenuItem value="event" id="event-id">
                Event
              </MenuItem>
              <MenuItem value="notification">Notification</MenuItem>
              <MenuItem value="timetable">Prayer Timing</MenuItem>
              <MenuItem value="boardmember">Board Members</MenuItem>
              <MenuItem value="service">Service</MenuItem>
              <MenuItem value="program">Program</MenuItem>
              <MenuItem value="donation">Donation</MenuItem>
            </Select>
          </Grid>
          <Button
            variant="contained"
            id="widget-btn"
            onClick={
              oldWidgetType.includes(assetType)
                ? handleGenerateOldWidget
                : handleGenerateWidget
            }
            disabled={
              loading ||
              isLoading ||
              (assetType === "donation" && productsLoading)
            }
            fullWidth
          >
            {loading ||
            isLoading ||
            (assetType === "donation" && productsLoading) ? (
              <CircularProgress size={24} />
            ) : (
              "Generate Widget"
            )}
          </Button>
          <br />
          <br />
          <Box display="flex" justifyContent="center">
            {tokens[assetType] && (
              <IconButton
                aria-label="copy"
                onClick={handleCopyToClipboard}
                style={{ padding: "8px", color: "#3f51b5" }}
              >
                <FileCopyIcon />
                <Typography variant="body2" style={{ marginLeft: "5px" }}>
                  Copy
                </Typography>
              </IconButton>
            )}
          </Box>

          <Typography variant="body2" textAlign="center">
            {tokens[assetType]
              ? (baseURL + "/" + assetType).slice(0, 40) + "......."
              : ""}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default WidgetComponent;
