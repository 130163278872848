import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
  TextField,
  DialogTitle,
  IconButton,
  Divider,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./RequestActionModal.css";
import styles from "./RequestActionModal.module.css";

import ConfirmChangeModal from "./ConfirmChangeModal";
import DetailsButtons from "./DetailsButtons";
import DetailFields from "./DetailFields";
import approvedBtnTick from "../../../Photos/Newuiphotos/VerificationCenter/approvedBtnTick.webp";
import rejectedBtnCross from "../../../Photos/Newuiphotos/VerificationCenter/rejectedBtnCross.webp";
import backAngleBracketIcon from "../../../Photos/Newuiphotos/VerificationCenter/backAngleBracketIcon.webp";
import toast from "react-hot-toast";
import moment from "moment";

interface RequestActionModalProps {
  open: boolean;
  onClose: () => void;
  requestDetails: any;
  logDetails: any;
  onConfirmAction: (action: string, reason: string) => void;
  isLoading: boolean;
}
const RequestActionModal = ({
  open,
  onClose,
  requestDetails,
  logDetails,
  onConfirmAction,
  isLoading,
}: RequestActionModalProps) => {
  const [reason, setReason] = useState("");
  const [isChangingModalVisbile, setIsChangingModalVisbile] = useState(false);
  const [showReasonConfirmation, setShowReasonConfirmation] = useState(false);
  const [showLogs, setShowLogs] = useState(false);
  const [changingStatusTo, setChangingStatusTo] = useState("");
  const [reasonError, setReasonError] = useState(false);
  const handleConfirm = (action) => {
    if (!reason) {
      toast.error("Reason is required!");
      setReasonError(true);
      return;
    }
    onClose();
    setReason("");
    setIsChangingModalVisbile(false);
    setShowReasonConfirmation(false);
    onConfirmAction(action, reason);
  };
  const handleApproveReject = (status: string) => {
    setChangingStatusTo(status);
    setShowReasonConfirmation(true);
  };
  const handleChangeStatusClick = (status: string) => {
    setChangingStatusTo(status);
    setIsChangingModalVisbile(true);
  };
  const handleShowReasonConfirmation = () => {
    setIsChangingModalVisbile(false);
    setShowReasonConfirmation(true);
  };
  useEffect(() => {
    console.log("logsData------------", logDetails);
  }, [logDetails]);
  return (
    <Dialog
      open={open}
      onClose={() => {
        showReasonConfirmation
          ? setShowReasonConfirmation(false)
          : setShowLogs(false);
        onClose();
      }}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "22px",
        },
      }}
    >
      <DialogTitle>
        {showReasonConfirmation
          ? `Reason for ${
              changingStatusTo === "approve" ? "Approval" : "Rejection"
            }`
          : showLogs
          ? "Logs"
          : "User Details"}
        <IconButton
          aria-label="close"
          onClick={() => {
            showReasonConfirmation
              ? setShowReasonConfirmation(false)
              : showLogs
              ? setShowLogs(false)
              : onClose();
          }}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className={styles["custom-scrollbar"]}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflowX: "hidden",
        }}
      >
        {showReasonConfirmation ? (
          <Box>
            <Box mt={2}>
              <TextField
                fullWidth
                label={`Reason for ${
                  changingStatusTo === "approve" ? "Approving" : "Rejecting"
                }`}
                sx={{ border: reasonError ? "1px solid red" : "" }}
                value={reason}
                onChange={(e) => {
                  setReasonError(false);
                  setReason(e.target.value);
                }}
                multiline
                rows={4}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 3,
                gap: 20,
              }}
            >
              <Button
                variant="contained"
                sx={{
                  width: "180px",
                  borderRadius: "12px",
                  bgcolor: "#B0B0B0",
                  color: "white",
                  "&:hover": { bgcolor: "#B0B0B0" },
                  // "&.Mui-disabled": { bgcolor: "#B0B0B0", color: "white" },
                  fontSize: "13px",
                }}
                onClick={() => {
                  setShowReasonConfirmation(false);
                }}
              >
                <Box
                  component="img"
                  src={backAngleBracketIcon}
                  width={"9px"}
                  marginRight="5px"
                />
                Back
              </Button>
              <Button
                variant="contained"
                sx={{
                  width: "180px",
                  borderRadius: "12px",
                  bgcolor:
                    changingStatusTo === "approve" ? "#00D23B" : "#F93737",
                  "&:hover": {
                    bgcolor:
                      changingStatusTo === "approve" ? "#00D23B" : "#F93737",
                  },
                  "&.Mui-disabled": { bgcolor: "#B0B0B0", color: "white" },
                  fontSize: "13px",
                }}
                onClick={() =>
                  handleConfirm(
                    changingStatusTo === "approve" ? "approved" : "rejected"
                  )
                }
              >
                <Box
                  component="img"
                  src={
                    changingStatusTo === "approve"
                      ? approvedBtnTick
                      : rejectedBtnCross
                  }
                  width={"11px"}
                  marginRight="5px"
                />
                {requestDetails.status === "pending" ||
                requestDetails.status === "inreview"
                  ? changingStatusTo === "approve"
                    ? "Confirm Approve"
                    : "Confirm Reject"
                  : changingStatusTo === "approve"
                  ? "Approve"
                  : "Reject"}
              </Button>
            </Box>
          </Box>
        ) : showLogs ? (
          <Box sx={{ padding: 2, width: "100%" }}>
            {/* Approved Section */}
            {logDetails
              .slice()
              .reverse()
              .map((item, index) => (
                <Box sx={{ marginBottom: 2 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {item.status} By
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#1B8368",
                      fontWeight: "bold",
                      marginBottom: 1,
                    }}
                  >
                    {item.updatedByUser}
                  </Typography>
                  <Typography variant="subtitle2" sx={{}}>
                    Date & Time
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#1B8368",
                      fontWeight: "bold",
                      marginBottom: 1,
                    }}
                  >
                    {moment
                      .utc(item.createdAt)
                      .local()
                      .format("DD MMM, YYYY | hh:mm A")}
                  </Typography>
                  <Typography variant="subtitle2" sx={{}}>
                    Reason of{" "}
                    {item.status === "approved" ? "Approval" : "Rejection"}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      //   border: "1px solid #ccc",
                      marginBottom: 2,
                      //   padding: 1,
                      borderRadius: 2,
                      //   minHeight: "60px",
                    }}
                    className={styles["custom-scrollbar"]}
                    style={{
                      maxHeight: item.message.length > 180 ? "200px" : "auto",
                      overflowY: item.message.length > 180 ? "auto" : "hidden",
                    }}
                  >
                    {item.message}
                  </Typography>

                  <Divider
                    // variant="middle"
                    sx={{ borderWidth: "thin", borderColor: "#e6e2e2" }}
                  />
                </Box>
              ))}
          </Box>
        ) : isLoading ? (
          <CircularProgress />
        ) : (
          <Box
            // sx={{ overflowX: "hidden" }}
            sx={{ width: "100%" }}
          >
            <DetailFields
              status={requestDetails?.status ?? ""}
              requestDetails={requestDetails}
            />
            <DetailsButtons
              status={requestDetails?.status ?? ""}
              requestDetails={requestDetails}
              handleChangeStatusClick={handleChangeStatusClick}
              handleApproveReject={handleApproveReject}
              setShowLogs={setShowLogs}
            />
          </Box>
        )}
      </DialogContent>
      <ConfirmChangeModal
        status={requestDetails?.status ?? ""}
        isChangingModalVisbile={isChangingModalVisbile}
        setIsChangingModalVisbile={setIsChangingModalVisbile}
        handleShowReasonConfirmation={handleShowReasonConfirmation}
      />
    </Dialog>
  );
};

export default RequestActionModal;
