import React from "react";

import FreezeMasjidTable from "../../../Components/FeedComponents/FreezeMasjidTable/FreezeMasjidTable";
import PageContainer from "../../Shared/PageContainer/PageContainer";

const FreezeMasjids = () => {
  return (
    <>
      <PageContainer pt={"60px"} title={"Freezed Masjids"}>
        <div className="RightFeedContainer">
          <FreezeMasjidTable />
        </div>
      </PageContainer>
    </>
  );
};

export default FreezeMasjids;
