import { gql } from "@apollo/client";

export const getMasjidById = () => {
  const query = `query GetMasjidById($id: String!) {
    getMasjidById(id: $id) {
      _id
      masjidName
      subscribers
      followers
    }
  }
`;

  return gql`
    ${query}
  `;
};
