import "./App.css";
import React from "react";
import { Navigate } from "react-router-dom";
import Login from "./Pages/AuthPages/Login/Login";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Snackbar from "./Components/SnackbarComponents/Snackbar";
import { AdminInterFace } from "./Components/FeedComponents/Feed/Feed";
import ForgotPassword from "./Pages/AuthPages/ForgotPassword/ForgotPassword";
import ResetPassword from "./Pages/AuthPages/ResetPassword/ResetPassword";
import ResetPasswordInitial from "./Pages/AuthPages/ResetPasswordInitial/ResetPasswordInitial";
import FreezeMasjids from "./Pages/DetailPages/FreezeMasjids/FreezeMasjids";
import FreezeAdmins from "./Pages/DetailPages/FreezeAdmins/FreezeAdmins";
import Masjids from "./Pages/DetailPages/Masjids/Masjids";
import Announcement from "./Pages/DetailPages/Announcement/Announcement";
import Admins from "./Pages/DetailPages/Admins/Admins";
import MasjidProfile from "./Pages/ProfilePages/MasjidProfile/MasjidProfile";
import AdminProfile from "./Pages/ProfilePages/AdminProfile/AdminProfile";
import Common_App from "./Common/Common_App";
import EventDetailsVanila from "./Pages/EventDetailsVanila/EventDetailsVanila";
import CRM from "./Components/CRMComponent/CRM";
import Menu from "./Components/MenuPage/Menu";
import VerificationCenter from "./Pages/DetailPages/VerificationCenter/VerificationCenter";
import ProgramDetailsVanila from "./Pages/ProgramDetailsVanila/ProgramDetailsVanila";
import ServiceDetailsVanila from "./Pages/ServiceDetailsVanilla/ServiceDetailsVanilla";
import BoardMemberDetailsVanila from "./Pages/BoardMemberDetialsVanila/BoardMemberDetailsVanila";

function App() {
  const adminString = localStorage.getItem("admin");
  const admin: AdminInterFace | null = adminString
    ? JSON.parse(adminString)
    : null;
  const routes = [
    {
      path: "/",
      element: <Dashboard />,
      protected: true,
    },
    {
      path: "*",
      element: <Dashboard />,
      protected: true,
    },

    {
      path: "/masjids",
      element: <Masjids />,
      protected: true,
    },
    {
      path: "/crm",
      element: <CRM />,
      protected: true,
    },
    {
      path: "/reset-password/:token",
      element: <ResetPassword />,
      protected: false,
    },
    {
      path: "/forgotpassword",
      element: <ForgotPassword />,
      protected: false,
    },
    {
      path: "/account/initial",
      element: <ResetPasswordInitial />,
      protected: false,
    },
    {
      path: "/freezeMasjids",
      element: <FreezeMasjids />,
      protected: true,
    },
    {
      path: "/announcements",
      element: <Announcement />,
      protected: true,
    },
    {
      path: "/admins",
      element: <Admins />,
      protected: true,
    },
    {
      path: "/freezeAdmins",
      element: <FreezeAdmins />,
      protected: true,
    },
    {
      path: "/verification-center",
      element: <VerificationCenter />,
      protected: true,
    },
    {
      path: "/masjid/:masjidId",
      element: <MasjidProfile />,
      protected: true,
    },
    {
      path: "/admins/:adminName",
      element: <AdminProfile />,
      protected: true,
    },
    {
      path: "/feed/:tab",
      element: <Menu />,
      protected: true,
    },
    {
      path: "/event-details/:id",
      element: <EventDetailsVanila />,
      protected: true,
    },
    {
      path: "/program-details/:id",
      element: <ProgramDetailsVanila />,
      protected: true,
    },
    {
      path: "/service-details/:id",
      element: <ServiceDetailsVanila />,
      protected: true,
    },
    {
      path: "/board-member-details/:id",
      element: <BoardMemberDetailsVanila />,
      protected: true,
    },
    {
      path: "/login",
      element: admin ? (
        admin.role === "superadmin" || admin.role === "admin" ? (
          <Navigate to="/" />
        ) : (
          <Login />
        )
      ) : (
        <Login />
      ),
    },
  ];

  return (
    <>
      <Snackbar />
      <Common_App routes={routes} />
    </>
  );
}

export default App;
