import React, { useEffect } from "react";
import {
  ServiceDetails,
  MyProvider,
} from "@msa-software-llc/connectmazjid-vanilaportal";
import { useNavigate } from "react-router-dom";
const ServiceDetailsVanila = () => {
  useEffect(() => {
    const styleElement = document.createElement("style");

    const loadDynamicStyles = async () => {
      const { default: vanillaPortalStyle } = await import(
        "@msa-software-llc/connectmazjid-vanilaportal/dist/style.css"
      );
      // Append the stylesheet to the document head
      styleElement.textContent = vanillaPortalStyle;
      document.head.appendChild(styleElement);
    };

    loadDynamicStyles();

    return () => {
      const indexOfMyCss = Array.from(document.head.children).indexOf(
        styleElement
      );
    };
  }, []);
  const navigation = useNavigate();
  return (
    <div className="bottom-nav-container main-menu">
      <MyProvider navigation={navigation}>
        <ServiceDetails />
      </MyProvider>
    </div>
  );
};

export default ServiceDetailsVanila;
